'use strict';

angular.module('windmanagerApp')
  .directive('mobileNav', function() {
    return {
      restrict: 'EA',
      link: function(scope, element, attrs) {
        jQuery(function() {
          jQuery('body').mobileNav({
            hideOnClickOutside: true,
            menuActiveClass: 'header-active',
            menuOpener: '.header-opener',
            menuDrop: '#header'
          });
          jQuery('body').mobileNav({
            hideOnClickOutside: true,
            menuActiveClass: 'menu-active',
            menuOpener: '.menu-opener',
            menuDrop: '.right-info'
          });
        });
      }
    };
  });
